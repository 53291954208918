"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = setIn;

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

function setIn(obj, path, updater) {
  if (!Array.isArray(path)) {
    throw new Error('path must be an array');
  }

  if (!path.length) {
    return updater(obj);
  }

  path = path.slice();
  var accessor = path.shift();

  if (typeof accessor === 'function') {
    if (Array.isArray(obj)) {
      obj.forEach(function (value, index) {
        if (accessor.call(obj, value, index)) {
          obj = setIn(obj, [index].concat(_toConsumableArray(path)), updater);
        }
      });
    } else if (obj) {
      Object.keys(obj).forEach(function (key) {
        if (accessor.call(obj, obj[key], key)) {
          obj = setIn(obj, [key].concat(_toConsumableArray(path)), updater);
        }
      });
    }

    return obj;
  }

  var value = typeof obj !== 'undefined' && obj[accessor];
  var nextObj = obj;

  if (typeof accessor === 'string' && (_typeof(nextObj) !== 'object' || Array.isArray(nextObj))) {
    nextObj = {};
  } else if (typeof accessor === 'number' && !Array.isArray(nextObj)) {
    nextObj = [];
  }

  if (typeof accessor === 'number') {
    if (updater || path.length) {
      if (accessor === -1) {
        return _toConsumableArray(nextObj).concat([setIn([], path, updater)]);
      }

      var nextValue = setIn(value, path, updater);

      if (typeof nextValue !== 'undefined') {
        if (nextValue === value) {
          return obj;
        } else {
          nextObj = _toConsumableArray(nextObj);
          nextObj[accessor] = nextValue;
          return nextObj;
        }
      }
    } // If updater returned undefined or no updater at all, delete the item


    if (accessor in nextObj) {
      nextObj = _toConsumableArray(nextObj);
      nextObj.splice(accessor, 1);
    }

    return nextObj;
  } else {
    if (updater || path.length) {
      var _nextValue = setIn(value, path, updater);

      if (typeof _nextValue !== 'undefined') {
        if (_nextValue === value) {
          return obj;
        } else {
          return _objectSpread({}, nextObj, _defineProperty({}, accessor, _nextValue));
        }
      }
    } // If updater returned undefined or no updater at all, delete the key


    if (accessor in nextObj) {
      nextObj = _objectSpread({}, nextObj);
      delete nextObj[accessor];
    }

    return nextObj;
  }
}

module.exports = exports.default;
module.exports.default = exports.default;