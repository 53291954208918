import { Group } from '@vx/group';
import { arc, pie, line, radialLine, area, stackOrderAscending, stackOrderDescending, stackOrderInsideOut, stackOrderNone, stackOrderReverse, stackOffsetExpand, stackOffsetDiverging, stackOffsetNone, stackOffsetSilhouette, stackOffsetWiggle, stack, linkHorizontal, linkVertical, linkRadial } from 'd3-shape';
import { path } from 'd3-path';
import { Point } from '@vx/point';
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

    return arr2;
  }
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

Arc.propTypes = {
  className: PropTypes.string,
  data: PropTypes.any,
  children: PropTypes.func,
  innerRef: PropTypes.func,
  centroid: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  innerRadius: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  outerRadius: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  cornerRadius: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  startAngle: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  endAngle: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  padAngle: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  padRadius: PropTypes.oneOfType([PropTypes.func, PropTypes.number])
};
function Arc(_ref) {
  var className = _ref.className,
      data = _ref.data,
      centroid = _ref.centroid,
      innerRadius = _ref.innerRadius,
      outerRadius = _ref.outerRadius,
      cornerRadius = _ref.cornerRadius,
      startAngle = _ref.startAngle,
      endAngle = _ref.endAngle,
      padAngle = _ref.padAngle,
      padRadius = _ref.padRadius,
      children = _ref.children,
      innerRef = _ref.innerRef,
      restProps = _objectWithoutProperties(_ref, ["className", "data", "centroid", "innerRadius", "outerRadius", "cornerRadius", "startAngle", "endAngle", "padAngle", "padRadius", "children", "innerRef"]);

  var arc$$1 = arc();
  if (centroid) arc$$1.centroid(centroid);
  if (innerRadius) arc$$1.innerRadius(innerRadius);
  if (outerRadius) arc$$1.outerRadius(outerRadius);
  if (cornerRadius) arc$$1.cornerRadius(cornerRadius);
  if (startAngle) arc$$1.startAngle(startAngle);
  if (endAngle) arc$$1.endAngle(endAngle);
  if (padAngle) arc$$1.padAngle(padAngle);
  if (padRadius) arc$$1.padRadius(padRadius);
  if (children) return children({
    path: arc$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-arc', className),
    d: arc$$1(data)
  }, restProps));
}

Pie.propTypes = {
  className: PropTypes.string,
  top: PropTypes.number,
  left: PropTypes.number,
  data: PropTypes.array,
  centroid: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  innerRadius: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  outerRadius: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  cornerRadius: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  startAngle: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  endAngle: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  padAngle: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  padRadius: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  pieValue: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  pieSort: PropTypes.func,
  pieSortValues: PropTypes.func,
  children: PropTypes.func
};
function Pie(_ref) {
  var className = _ref.className,
      top = _ref.top,
      left = _ref.left,
      data = _ref.data,
      centroid = _ref.centroid,
      _ref$innerRadius = _ref.innerRadius,
      innerRadius = _ref$innerRadius === void 0 ? 0 : _ref$innerRadius,
      outerRadius = _ref.outerRadius,
      cornerRadius = _ref.cornerRadius,
      startAngle = _ref.startAngle,
      endAngle = _ref.endAngle,
      padAngle = _ref.padAngle,
      padRadius = _ref.padRadius,
      pieSort = _ref.pieSort,
      pieSortValues = _ref.pieSortValues,
      pieValue = _ref.pieValue,
      children = _ref.children,
      restProps = _objectWithoutProperties(_ref, ["className", "top", "left", "data", "centroid", "innerRadius", "outerRadius", "cornerRadius", "startAngle", "endAngle", "padAngle", "padRadius", "pieSort", "pieSortValues", "pieValue", "children"]);

  var path$$1 = arc();
  if (innerRadius != null) path$$1.innerRadius(innerRadius);
  if (outerRadius != null) path$$1.outerRadius(outerRadius);
  if (cornerRadius != null) path$$1.cornerRadius(cornerRadius);
  if (padRadius != null) path$$1.padRadius(padRadius);
  var pie$$1 = pie();
  if (pieSort !== undefined) pie$$1.sort(pieSort);
  if (pieSortValues !== undefined) pie$$1.sortValues(pieSortValues);
  if (pieValue) pie$$1.value(pieValue);
  if (padAngle != null) pie$$1.padAngle(padAngle);
  if (startAngle != null) pie$$1.startAngle(startAngle);
  if (endAngle != null) pie$$1.endAngle(endAngle);
  var arcs = pie$$1(data);
  if (children) return children({
    arcs: arcs,
    path: path$$1,
    pie: pie$$1
  });
  return React.createElement(Group, {
    className: "vx-pie-arcs-group",
    top: top,
    left: left
  }, arcs.map(function (arc$$1, i) {
    return React.createElement("g", {
      key: "pie-arc-".concat(i)
    }, React.createElement("path", _extends({
      className: cx('vx-pie-arc', className),
      d: path$$1(arc$$1)
    }, restProps)), centroid && centroid(path$$1.centroid(arc$$1), arc$$1));
  }));
}

Line.propTypes = {
  className: PropTypes.string,
  innerRef: PropTypes.func,
  from: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  to: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  })
};
function Line(_ref) {
  var _ref$from = _ref.from,
      from = _ref$from === void 0 ? new Point({
    x: 0,
    y: 0
  }) : _ref$from,
      _ref$to = _ref.to,
      to = _ref$to === void 0 ? new Point({
    x: 1,
    y: 1
  }) : _ref$to,
      _ref$fill = _ref.fill,
      fill = _ref$fill === void 0 ? 'transparent' : _ref$fill,
      _ref$className = _ref.className,
      className = _ref$className === void 0 ? '' : _ref$className,
      innerRef = _ref.innerRef,
      restProps = _objectWithoutProperties(_ref, ["from", "to", "fill", "className", "innerRef"]);

  return React.createElement("line", _extends({
    ref: innerRef,
    className: cx('vx-line', className),
    x1: from.x,
    y1: from.y,
    x2: to.x,
    y2: to.y,
    fill: fill
  }, restProps));
}

LinePath.propTypes = {
  innerRef: PropTypes.func,
  data: PropTypes.array,
  curve: PropTypes.func,
  defined: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  x: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  y: PropTypes.oneOfType([PropTypes.func, PropTypes.number])
};
function LinePath(_ref) {
  var children = _ref.children,
      data = _ref.data,
      x = _ref.x,
      y = _ref.y,
      _ref$fill = _ref.fill,
      fill = _ref$fill === void 0 ? 'transparent' : _ref$fill,
      className = _ref.className,
      curve = _ref.curve,
      innerRef = _ref.innerRef,
      _ref$defined = _ref.defined,
      defined = _ref$defined === void 0 ? function () {
    return true;
  } : _ref$defined,
      restProps = _objectWithoutProperties(_ref, ["children", "data", "x", "y", "fill", "className", "curve", "innerRef", "defined"]);

  var path$$1 = line();
  if (x) path$$1.x(x);
  if (y) path$$1.y(y);
  if (defined) path$$1.defined(defined);
  if (curve) path$$1.curve(curve);
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-linepath', className),
    d: path$$1(data),
    fill: fill
  }, restProps));
}

LineRadial.propTypes = {
  innerRef: PropTypes.func,
  className: PropTypes.string,
  data: PropTypes.any,
  curve: PropTypes.func,
  angle: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  radius: PropTypes.oneOfType([PropTypes.func, PropTypes.number])
};
function LineRadial(_ref) {
  var className = _ref.className,
      angle = _ref.angle,
      radius = _ref.radius,
      defined = _ref.defined,
      curve = _ref.curve,
      data = _ref.data,
      innerRef = _ref.innerRef,
      children = _ref.children,
      _ref$fill = _ref.fill,
      fill = _ref$fill === void 0 ? 'transparent' : _ref$fill,
      restProps = _objectWithoutProperties(_ref, ["className", "angle", "radius", "defined", "curve", "data", "innerRef", "children", "fill"]);

  var path$$1 = radialLine();
  if (angle) path$$1.angle(angle);
  if (radius) path$$1.radius(radius);
  if (defined) path$$1.defined(defined);
  if (curve) path$$1.curve(curve);
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-line-radial', className),
    d: path$$1(data),
    fill: fill
  }, restProps));
}

Area.propTypes = {
  children: PropTypes.func,
  className: PropTypes.string,
  data: PropTypes.any,
  defined: PropTypes.func,
  innerRef: PropTypes.func,
  curve: PropTypes.func,
  x: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  x0: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  x1: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  y: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  y0: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  y1: PropTypes.oneOfType([PropTypes.func, PropTypes.number])
};
function Area(_ref) {
  var children = _ref.children,
      x = _ref.x,
      x0 = _ref.x0,
      x1 = _ref.x1,
      y = _ref.y,
      y0 = _ref.y0,
      y1 = _ref.y1,
      data = _ref.data,
      _ref$defined = _ref.defined,
      defined = _ref$defined === void 0 ? function () {
    return true;
  } : _ref$defined,
      className = _ref.className,
      curve = _ref.curve,
      innerRef = _ref.innerRef,
      restProps = _objectWithoutProperties(_ref, ["children", "x", "x0", "x1", "y", "y0", "y1", "data", "defined", "className", "curve", "innerRef"]);

  var path$$1 = area();
  if (x) path$$1.x(x);
  if (x0) path$$1.x0(x0);
  if (x1) path$$1.x1(x1);
  if (y) path$$1.y(y);
  if (y0) path$$1.y0(y0);
  if (y1) path$$1.y1(y1);
  if (defined) path$$1.defined(defined);
  if (curve) path$$1.curve(curve);
  if (children) return children({
    path: path$$1
  });
  return React.createElement("g", null, React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-area', className),
    d: path$$1(data)
  }, restProps)));
}

AreaClosed.propTypes = {
  children: PropTypes.func,
  className: PropTypes.string,
  data: PropTypes.any,
  defined: PropTypes.func,
  innerRef: PropTypes.func,
  curve: PropTypes.func,
  yScale: PropTypes.func,
  x: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  x0: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  x1: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  y1: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  y0: PropTypes.oneOfType([PropTypes.func, PropTypes.number])
};
function AreaClosed(_ref) {
  var x = _ref.x,
      x0 = _ref.x0,
      x1 = _ref.x1,
      y = _ref.y,
      y1 = _ref.y1,
      y0 = _ref.y0,
      yScale = _ref.yScale,
      data = _ref.data,
      _ref$defined = _ref.defined,
      defined = _ref$defined === void 0 ? function () {
    return true;
  } : _ref$defined,
      className = _ref.className,
      curve = _ref.curve,
      innerRef = _ref.innerRef,
      children = _ref.children,
      restProps = _objectWithoutProperties(_ref, ["x", "x0", "x1", "y", "y1", "y0", "yScale", "data", "defined", "className", "curve", "innerRef", "children"]);

  var path$$1 = area();
  if (x) path$$1.x(x);
  if (x0) path$$1.x0(x0);
  if (x1) path$$1.x1(x1);

  if (y0) {
    path$$1.y0(y0);
  } else {
    path$$1.y0(yScale.range()[0]);
  }

  if (y && !y1) path$$1.y1(y);
  if (y1 && !y) path$$1.y1(y1);
  if (defined) path$$1.defined(defined);
  if (curve) path$$1.curve(curve);
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-area-closed', className),
    d: path$$1(data)
  }, restProps));
}

var STACK_ORDERS = {
  ascending: stackOrderAscending,
  descending: stackOrderDescending,
  insideout: stackOrderInsideOut,
  none: stackOrderNone,
  reverse: stackOrderReverse
};
var STACK_ORDER_NAMES = Object.keys(STACK_ORDERS);
function stackOrder(order) {
  return STACK_ORDERS[order] || STACK_ORDERS.none;
}

var STACK_OFFSETS = {
  expand: stackOffsetExpand,
  diverging: stackOffsetDiverging,
  none: stackOffsetNone,
  silhouette: stackOffsetSilhouette,
  wiggle: stackOffsetWiggle
};
var STACK_OFFSET_NAMES = Object.keys(STACK_OFFSETS);
function stackOffset(offset) {
  return STACK_OFFSETS[offset] || STACK_OFFSETS.none;
}

Stack.propTypes = {
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  top: PropTypes.number,
  left: PropTypes.number,
  curve: PropTypes.func,
  color: PropTypes.func,
  keys: PropTypes.array,
  children: PropTypes.func,
  x: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  x0: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  x1: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  y: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  y0: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  y1: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  defined: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  order: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.string]),
  offset: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.string])
};
function Stack(_ref) {
  var className = _ref.className,
      top = _ref.top,
      left = _ref.left,
      keys = _ref.keys,
      data = _ref.data,
      curve = _ref.curve,
      defined = _ref.defined,
      x = _ref.x,
      x0 = _ref.x0,
      x1 = _ref.x1,
      y0 = _ref.y0,
      y1 = _ref.y1,
      value = _ref.value,
      order = _ref.order,
      offset = _ref.offset,
      color = _ref.color,
      children = _ref.children,
      restProps = _objectWithoutProperties(_ref, ["className", "top", "left", "keys", "data", "curve", "defined", "x", "x0", "x1", "y0", "y1", "value", "order", "offset", "color", "children"]);

  var stack$$1 = stack();
  if (keys) stack$$1.keys(keys);
  if (value) stack$$1.value(value);
  if (order) stack$$1.order(stackOrder(order));
  if (offset) stack$$1.offset(stackOffset(offset));
  var path$$1 = area();
  if (x) path$$1.x(x);
  if (x0) path$$1.x0(x0);
  if (x1) path$$1.x1(x1);
  if (y0) path$$1.y0(y0);
  if (y1) path$$1.y1(y1);
  if (curve) path$$1.curve(curve);
  if (defined) path$$1.defined(defined);
  var stacks = stack$$1(data);
  if (children) return children({
    stacks: stacks,
    path: path$$1,
    stack: stack$$1
  });
  return React.createElement(Group, {
    top: top,
    left: left
  }, stacks.map(function (series, i) {
    return React.createElement("path", _extends({
      className: cx('vx-stack', className),
      key: "stack-".concat(i, "-").concat(series.key || ''),
      d: path$$1(series),
      fill: color(series.key, i)
    }, restProps));
  }));
}

AreaStack.propTypes = {
  className: PropTypes.string,
  top: PropTypes.number,
  left: PropTypes.number,
  keys: PropTypes.array,
  data: PropTypes.array,
  curve: PropTypes.func,
  color: PropTypes.func,
  children: PropTypes.func,
  x: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  x0: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  x1: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  y: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  y0: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  y1: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  defined: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  order: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.string]),
  offset: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.string])
};
function AreaStack(_ref) {
  var className = _ref.className,
      top = _ref.top,
      left = _ref.left,
      keys = _ref.keys,
      data = _ref.data,
      curve = _ref.curve,
      defined = _ref.defined,
      x = _ref.x,
      x0 = _ref.x0,
      x1 = _ref.x1,
      y0 = _ref.y0,
      y1 = _ref.y1,
      value = _ref.value,
      order = _ref.order,
      offset = _ref.offset,
      color = _ref.color,
      children = _ref.children,
      restProps = _objectWithoutProperties(_ref, ["className", "top", "left", "keys", "data", "curve", "defined", "x", "x0", "x1", "y0", "y1", "value", "order", "offset", "color", "children"]);

  return React.createElement(Stack, _extends({
    className: className,
    top: top,
    left: left,
    keys: keys,
    data: data,
    curve: curve,
    defined: defined,
    x: x,
    x0: x0,
    x1: x1,
    y0: y0,
    y1: y1,
    value: value,
    order: order,
    offset: offset,
    color: color
  }, restProps), !!children ? children : function (_ref2) {
    var stacks = _ref2.stacks,
        path$$1 = _ref2.path,
        stack$$1 = _ref2.stack;
    return stacks.map(function (series, i) {
      return React.createElement("path", _extends({
        className: cx('vx-area-stack', className),
        key: "area-stack-".concat(i, "-").concat(series.key || ''),
        d: path$$1(series),
        fill: color(series.key, i)
      }, restProps));
    });
  });
}

Bar.propTypes = {
  innerRef: PropTypes.func,
  className: PropTypes.string
};
function Bar(_ref) {
  var className = _ref.className,
      innerRef = _ref.innerRef,
      restProps = _objectWithoutProperties(_ref, ["className", "innerRef"]);

  return React.createElement("rect", _extends({
    ref: innerRef,
    className: cx('vx-bar', className)
  }, restProps));
}

function objHasMethod(obj, funcName) {
  return !!obj[funcName] && typeof obj[funcName] === 'function';
}

BarGroup.propTypes = {
  /**
   * An array of bar group objects.
   */
  data: PropTypes.array.isRequired,

  /**
   * ```js
   * x0(barGroup)
   * ```
   * An accessor function that returns the `x0` value for each datum in *props*.**data**.
   */
  x0: PropTypes.func.isRequired,

  /**
   * ```js
   * x0Scale(x0(barGroup))
   * ```
   * A scale function that returns the x position of the bar group.
   */
  x0Scale: PropTypes.func.isRequired,

  /**
   * ```js
   * x1Scale(key)
   * ```
   * A scale function that returns the x position of the bar within a bar group.
   */
  x1Scale: PropTypes.func.isRequired,

  /**
   * ```js
   * yScale(value)
   * ```
   * A scale function that retuns the y position of the bar within a bar group. `value` is the value of the `key` in the bar group.
   */
  yScale: PropTypes.func.isRequired,

  /**
   * ```js
   * color(key, barIndex)
   * ```
   * A function that returns color for each bar within a bar group.
   */
  color: PropTypes.func.isRequired,

  /**
   * An array of strings containing the key for each bar group. Each bar within a bar group will follow the order of this array.
   */
  keys: PropTypes.array.isRequired,

  /**
   * Height is used to align the bottom of the the bars. barHeight = height - yScale(bar.value), where bar.y = yScale(bar.value).
   */
  height: PropTypes.number.isRequired,

  /**
   * Add a class name to the containing `<g>` element.
   */
  className: PropTypes.string,

  /**
   * A top pixel offset applied to the entire bar group.
   */
  top: PropTypes.number,

  /**
   * A left pixel offset applied to the entire bar group.
   */
  left: PropTypes.number,

  /**
   * A function that returns a react component. Useful for generating the bar group data with full control over what is rendered. The functions first argument will be the bar groups data as an array of objects with the following properties:
   *
   *  - `index<number>` - the index of the group based on *props*.**data** array.
   *  - `x0<number>` - the position of the group based on *props*.**x0** & *props*.**x0Scale**.
   *  - `bars<array>` - array of objects, ordered by *props*.**keys**, with the following properties:
   *    + `index<number>` - the index of the bar for the current group.
   *    + `key<string>` - the key of the bar.
   *    + `width<number>` - the width of the bar. This will be `x1Scale.bandwidth()`. If `x1Scale` does not have a bandwidth property, then it becomes:
   *      ```js
   *      x1Range = x1Scale.range();
   *      x1Domain = x1Scale.domain();
   *      barWidth = Math.abs(x1Range[x1Range.length - 1] - x1Range[0]) / x1Domain.length
   *      ```
   *    + `height<number>` - the height of the bar.
   *    + `x<number>` - the x position of the bar.
   *    + `y<number>` - the y position of the bar.
   *    + `color<string>` - the color of the bar.
   */
  children: PropTypes.func
};
/**
 * Generates bar groups as an array of objects and renders `<rect />`s for each datum grouped by `key`. A general setup might look like this:
 *
 * ```js
 * const data = [{
 *  date: date1,
 *  key1: value,
 *  key2: value,
 *  key3: value
 * }, {
 *  date: date2,
 *  key1: value,
 *  key2: value,
 *  key3: value,
 * }];
 *
 * const x0 = d => d.date;
 * const keys = [key1, key2, key3];
 *
 * const x0Scale = scaleBand({
 *  domain: data.map(x0),
 *  padding: 0.2
 * });
 * const x1Scale = scaleBand({
 *  domain: keys,
 *  padding: 0.1
 * });
 * const yScale = scaleLinear({
 *   domain: [0, Math.max(...data.map(d => Math.max(...keys.map(key => d[key]))))]
 * });
 * const color = scaleOrdinal({
 *   domain: keys,
 *   range: [blue, green, purple]
 * });
 * ```
 *
 * Example: [https://vx-demo.now.sh/bargroup](https://vx-demo.now.sh/bargroup)
 */

function BarGroup(_ref) {
  var data = _ref.data,
      className = _ref.className,
      top = _ref.top,
      left = _ref.left,
      x0 = _ref.x0,
      x0Scale = _ref.x0Scale,
      x1Scale = _ref.x1Scale,
      yScale = _ref.yScale,
      color = _ref.color,
      keys = _ref.keys,
      height = _ref.height,
      children = _ref.children,
      restProps = _objectWithoutProperties(_ref, ["data", "className", "top", "left", "x0", "x0Scale", "x1Scale", "yScale", "color", "keys", "height", "children"]);

  var x1Range = x1Scale.range();
  var x1Domain = x1Scale.domain();
  var barWidth = objHasMethod(x1Scale, 'bandwidth') ? x1Scale.bandwidth() : Math.abs(x1Range[x1Range.length - 1] - x1Range[0]) / x1Domain.length;
  var barGroups = data.map(function (group, i) {
    return {
      index: i,
      x0: x0Scale(x0(group)),
      bars: keys.map(function (key, j) {
        var value = group[key];
        return {
          index: j,
          key: key,
          value: value,
          width: barWidth,
          x: x1Scale(key),
          y: yScale(value),
          color: color(key, j),
          height: height - yScale(value)
        };
      })
    };
  });
  if (children) return children(barGroups);
  return React.createElement(Group, {
    className: cx('vx-bar-group', className),
    top: top,
    left: left
  }, barGroups.map(function (barGroup) {
    return React.createElement(Group, {
      key: "bar-group-".concat(barGroup.index, "-").concat(barGroup.x0),
      left: barGroup.x0
    }, barGroup.bars.map(function (bar) {
      return React.createElement(Bar, _extends({
        key: "bar-group-bar-".concat(barGroup.index, "-").concat(bar.index, "-").concat(bar.value, "-").concat(bar.key),
        x: bar.x,
        y: bar.y,
        width: bar.width,
        height: bar.height,
        fill: bar.color
      }, restProps));
    }));
  }));
}

BarGroupHorizontal.propTypes = {
  data: PropTypes.array.isRequired,
  y0: PropTypes.func.isRequired,
  y0Scale: PropTypes.func.isRequired,
  y1Scale: PropTypes.func.isRequired,
  xScale: PropTypes.func.isRequired,
  color: PropTypes.func.isRequired,
  keys: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  className: PropTypes.string,
  x: PropTypes.func,
  top: PropTypes.number,
  left: PropTypes.number,
  children: PropTypes.func
};
function BarGroupHorizontal(_ref) {
  var data = _ref.data,
      className = _ref.className,
      top = _ref.top,
      left = _ref.left,
      _ref$x = _ref.x,
      x = _ref$x === void 0 ? function (val) {
    return 0;
  } : _ref$x,
      y0 = _ref.y0,
      y0Scale = _ref.y0Scale,
      y1Scale = _ref.y1Scale,
      xScale = _ref.xScale,
      color = _ref.color,
      keys = _ref.keys,
      width = _ref.width,
      children = _ref.children,
      restProps = _objectWithoutProperties(_ref, ["data", "className", "top", "left", "x", "y0", "y0Scale", "y1Scale", "xScale", "color", "keys", "width", "children"]);

  var y1Range = y1Scale.range();
  var y1Domain = y1Scale.domain();
  var barHeight = objHasMethod(y1Scale, 'bandwidth') ? y1Scale.bandwidth() : Math.abs(y1Range[y1Range.length - 1] - y1Range[0]) / y1Domain.length;
  var barGroups = data.map(function (group, i) {
    return {
      index: i,
      y0: y0Scale(y0(group)),
      bars: keys.map(function (key, j) {
        var value = group[key];
        return {
          index: j,
          key: key,
          value: value,
          height: barHeight,
          x: x(value),
          y: y1Scale(key),
          color: color(key, j),
          width: width - xScale(value)
        };
      })
    };
  });
  if (children) return children(barGroups);
  return React.createElement(Group, {
    className: cx('vx-bar-group-horizontal', className),
    top: top,
    left: left
  }, barGroups.map(function (barGroup) {
    return React.createElement(Group, {
      key: "bar-group-".concat(barGroup.index, "-").concat(barGroup.y0),
      top: barGroup.y0
    }, barGroup.bars.map(function (bar) {
      return React.createElement(Bar, _extends({
        key: "bar-group-bar-".concat(barGroup.index, "-").concat(bar.index, "-").concat(bar.value, "-").concat(bar.key),
        x: bar.x,
        y: bar.y,
        width: bar.width,
        height: bar.height,
        fill: bar.color
      }, restProps));
    }));
  }));
}

BarStack.propTypes = {
  data: PropTypes.array.isRequired,
  x: PropTypes.func.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  color: PropTypes.func.isRequired,
  keys: PropTypes.array.isRequired,
  className: PropTypes.string,
  top: PropTypes.number,
  left: PropTypes.number,
  children: PropTypes.func,
  y0: PropTypes.func,
  y1: PropTypes.func,
  order: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.string]),
  offset: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.func, PropTypes.number])
};
function BarStack(_ref) {
  var data = _ref.data,
      className = _ref.className,
      top = _ref.top,
      left = _ref.left,
      x = _ref.x,
      _ref$y = _ref.y0,
      y0 = _ref$y === void 0 ? function (d) {
    return d[0];
  } : _ref$y,
      _ref$y2 = _ref.y1,
      y1 = _ref$y2 === void 0 ? function (d) {
    return d[1];
  } : _ref$y2,
      xScale = _ref.xScale,
      yScale = _ref.yScale,
      color = _ref.color,
      keys = _ref.keys,
      value = _ref.value,
      order = _ref.order,
      offset = _ref.offset,
      children = _ref.children,
      restProps = _objectWithoutProperties(_ref, ["data", "className", "top", "left", "x", "y0", "y1", "xScale", "yScale", "color", "keys", "value", "order", "offset", "children"]);

  var stack$$1 = stack();
  if (keys) stack$$1.keys(keys);
  if (value) stack$$1.value(value);
  if (order) stack$$1.order(stackOrder(order));
  if (offset) stack$$1.offset(stackOffset(offset));
  var stacks = stack$$1(data);
  var xRange = xScale.range();
  var xDomain = xScale.domain();
  var barWidth = objHasMethod(xScale, 'bandwidth') ? xScale.bandwidth() : Math.abs(xRange[xRange.length - 1] - xRange[0]) / xDomain.length;
  var barStacks = stacks.map(function (barStack, i) {
    var key = barStack.key;
    return {
      index: i,
      key: key,
      bars: barStack.map(function (bar, j) {
        var barHeight = yScale(y0(bar)) - yScale(y1(bar));
        var barY = yScale(y1(bar));
        var barX = objHasMethod(xScale, 'bandwidth') ? xScale(x(bar.data)) : Math.max(xScale(x(bar.data)) - barWidth / 2);
        return {
          bar: bar,
          key: key,
          index: j,
          height: barHeight,
          width: barWidth,
          x: barX,
          y: barY,
          color: color(barStack.key, j)
        };
      })
    };
  });
  if (children) return children(barStacks);
  return React.createElement(Group, {
    className: cx('vx-bar-stack', className),
    top: top,
    left: left
  }, barStacks.map(function (barStack) {
    return barStack.bars.map(function (bar) {
      return React.createElement(Bar, _extends({
        key: "bar-stack-".concat(barStack.index, "-").concat(bar.index),
        x: bar.x,
        y: bar.y,
        height: bar.height,
        width: bar.width,
        fill: bar.color
      }, restProps));
    });
  }));
}

BarStackHorizontal.propTypes = {
  data: PropTypes.array.isRequired,
  y: PropTypes.func.isRequired,
  x0: PropTypes.func,
  x1: PropTypes.func,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  color: PropTypes.func.isRequired,
  keys: PropTypes.array.isRequired,
  className: PropTypes.string,
  top: PropTypes.number,
  left: PropTypes.number,
  order: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.string]),
  offset: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  children: PropTypes.func
};
function BarStackHorizontal(_ref) {
  var data = _ref.data,
      className = _ref.className,
      top = _ref.top,
      left = _ref.left,
      y = _ref.y,
      _ref$x = _ref.x0,
      x0 = _ref$x === void 0 ? function (d) {
    return d[0];
  } : _ref$x,
      _ref$x2 = _ref.x1,
      x1 = _ref$x2 === void 0 ? function (d) {
    return d[1];
  } : _ref$x2,
      xScale = _ref.xScale,
      yScale = _ref.yScale,
      color = _ref.color,
      keys = _ref.keys,
      value = _ref.value,
      order = _ref.order,
      offset = _ref.offset,
      children = _ref.children,
      restProps = _objectWithoutProperties(_ref, ["data", "className", "top", "left", "y", "x0", "x1", "xScale", "yScale", "color", "keys", "value", "order", "offset", "children"]);

  var stack$$1 = stack();
  if (keys) stack$$1.keys(keys);
  if (value) stack$$1.value(value);
  if (order) stack$$1.order(stackOrder(order));
  if (offset) stack$$1.offset(stackOffset(offset));
  var stacks = stack$$1(data);
  var yRange = yScale.range();
  var yDomain = yScale.domain();
  var barHeight = objHasMethod(yScale, 'bandwidth') ? yScale.bandwidth() : Math.abs(yRange[yRange.length - 1] - yRange[0]) / yDomain.length;
  var barStacks = stacks.map(function (barStack, i) {
    var key = barStack.key;
    return {
      index: i,
      key: key,
      bars: barStack.map(function (bar, j) {
        var barWidth = xScale(x1(bar)) - xScale(x0(bar));
        var barX = xScale(x0(bar));
        var barY = objHasMethod(yScale, 'bandwidth') ? yScale(y(bar.data)) : Math.max(yScale(y(bar.data)) - barWidth / 2);
        return {
          bar: bar,
          key: key,
          index: j,
          height: barHeight,
          width: barWidth,
          x: barX,
          y: barY,
          color: color(barStack.key, j)
        };
      })
    };
  });
  if (children) return children(barStacks);
  return React.createElement(Group, {
    className: cx('vx-bar-stack-horizontal', className),
    top: top,
    left: left
  }, barStacks.map(function (barStack) {
    return barStack.bars.map(function (bar) {
      return React.createElement(Bar, _extends({
        key: "bar-stack-".concat(barStack.index, "-").concat(bar.index),
        x: bar.x,
        y: bar.y,
        height: bar.height,
        width: bar.width,
        fill: bar.color
      }, restProps));
    });
  }));
}

var degreesToRadians = function degreesToRadians(degrees) {
  return Math.PI / 180 * degrees;
};

function pathHorizontalDiagonal(_ref) {
  var source = _ref.source,
      target = _ref.target,
      x = _ref.x,
      y = _ref.y;
  return function (data) {
    var link = linkHorizontal();
    link.x(x);
    link.y(y);
    link.source(source);
    link.target(target);
    return link(data);
  };
}
LinkHorizontal.propTypes = {
  innerRef: PropTypes.func,
  x: PropTypes.func,
  y: PropTypes.func,
  source: PropTypes.func,
  target: PropTypes.func,
  path: PropTypes.func,
  children: PropTypes.func
};
function LinkHorizontal(_ref2) {
  var className = _ref2.className,
      innerRef = _ref2.innerRef,
      data = _ref2.data,
      path$$1 = _ref2.path,
      _ref2$x = _ref2.x,
      x = _ref2$x === void 0 ? function (d) {
    return d.y;
  } : _ref2$x,
      _ref2$y = _ref2.y,
      y = _ref2$y === void 0 ? function (d) {
    return d.x;
  } : _ref2$y,
      _ref2$source = _ref2.source,
      source = _ref2$source === void 0 ? function (d) {
    return d.source;
  } : _ref2$source,
      _ref2$target = _ref2.target,
      target = _ref2$target === void 0 ? function (d) {
    return d.target;
  } : _ref2$target,
      children = _ref2.children,
      restProps = _objectWithoutProperties(_ref2, ["className", "innerRef", "data", "path", "x", "y", "source", "target", "children"]);

  path$$1 = path$$1 || pathHorizontalDiagonal({
    source: source,
    target: target,
    x: x,
    y: y
  });
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-link vx-link-horizontal-diagonal', className),
    d: path$$1(data)
  }, restProps));
}

function pathVerticalDiagonal(_ref) {
  var source = _ref.source,
      target = _ref.target,
      x = _ref.x,
      y = _ref.y;
  return function (data) {
    var link = linkVertical();
    link.x(x);
    link.y(y);
    link.source(source);
    link.target(target);
    return link(data);
  };
}
LinkVertical.propTypes = {
  innerRef: PropTypes.func,
  x: PropTypes.func,
  y: PropTypes.func,
  source: PropTypes.func,
  target: PropTypes.func,
  path: PropTypes.func
};
function LinkVertical(_ref2) {
  var className = _ref2.className,
      innerRef = _ref2.innerRef,
      data = _ref2.data,
      path$$1 = _ref2.path,
      _ref2$x = _ref2.x,
      x = _ref2$x === void 0 ? function (d) {
    return d.x;
  } : _ref2$x,
      _ref2$y = _ref2.y,
      y = _ref2$y === void 0 ? function (d) {
    return d.y;
  } : _ref2$y,
      _ref2$source = _ref2.source,
      source = _ref2$source === void 0 ? function (d) {
    return d.source;
  } : _ref2$source,
      _ref2$target = _ref2.target,
      target = _ref2$target === void 0 ? function (d) {
    return d.target;
  } : _ref2$target,
      children = _ref2.children,
      restProps = _objectWithoutProperties(_ref2, ["className", "innerRef", "data", "path", "x", "y", "source", "target", "children"]);

  path$$1 = path$$1 || pathVerticalDiagonal({
    source: source,
    target: target,
    x: x,
    y: y
  });
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-link vx-link-vertical-diagonal', className),
    d: path$$1(data)
  }, restProps));
}

function pathRadialDiagonal(_ref) {
  var source = _ref.source,
      target = _ref.target,
      angle = _ref.angle,
      radius = _ref.radius;
  return function (data) {
    var link = linkRadial();
    link.angle(angle);
    link.radius(radius);
    link.source(source);
    link.target(target);
    return link(data);
  };
}
LinkRadial.propTypes = {
  innerRef: PropTypes.func,
  angle: PropTypes.func,
  radius: PropTypes.func,
  source: PropTypes.func,
  target: PropTypes.func,
  path: PropTypes.func,
  children: PropTypes.func
};
function LinkRadial(_ref2) {
  var className = _ref2.className,
      innerRef = _ref2.innerRef,
      data = _ref2.data,
      path$$1 = _ref2.path,
      _ref2$angle = _ref2.angle,
      angle = _ref2$angle === void 0 ? function (d) {
    return d.x;
  } : _ref2$angle,
      _ref2$radius = _ref2.radius,
      radius = _ref2$radius === void 0 ? function (d) {
    return d.y;
  } : _ref2$radius,
      _ref2$source = _ref2.source,
      source = _ref2$source === void 0 ? function (d) {
    return d.source;
  } : _ref2$source,
      _ref2$target = _ref2.target,
      target = _ref2$target === void 0 ? function (d) {
    return d.target;
  } : _ref2$target,
      children = _ref2.children,
      restProps = _objectWithoutProperties(_ref2, ["className", "innerRef", "data", "path", "angle", "radius", "source", "target", "children"]);

  path$$1 = path$$1 || pathRadialDiagonal({
    source: source,
    target: target,
    angle: angle,
    radius: radius
  });
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-link vx-link-radial-diagonal', className),
    d: path$$1(data)
  }, restProps));
}

function pathHorizontalCurve(_ref) {
  var source = _ref.source,
      target = _ref.target,
      x = _ref.x,
      y = _ref.y,
      percent = _ref.percent;
  return function (data) {
    var sourceData = source(data);
    var targetData = target(data);
    var sx = x(sourceData);
    var sy = y(sourceData);
    var tx = x(targetData);
    var ty = y(targetData);
    var dx = tx - sx;
    var dy = ty - sy;
    var ix = percent * (dx + dy);
    var iy = percent * (dy - dx);
    var path$$1 = path();
    path$$1.moveTo(sx, sy);
    path$$1.bezierCurveTo(sx + ix, sy + iy, tx + iy, ty - ix, tx, ty);
    return path$$1.toString();
  };
}
LinkHorizontalCurve.propTypes = {
  innerRef: PropTypes.func,
  percent: PropTypes.number,
  x: PropTypes.func,
  y: PropTypes.func,
  source: PropTypes.func,
  target: PropTypes.func,
  path: PropTypes.func,
  children: PropTypes.func
};
function LinkHorizontalCurve(_ref2) {
  var className = _ref2.className,
      innerRef = _ref2.innerRef,
      data = _ref2.data,
      path$$1 = _ref2.path,
      _ref2$x = _ref2.x,
      x = _ref2$x === void 0 ? function (d) {
    return d.y;
  } : _ref2$x,
      _ref2$y = _ref2.y,
      y = _ref2$y === void 0 ? function (d) {
    return d.x;
  } : _ref2$y,
      _ref2$source = _ref2.source,
      source = _ref2$source === void 0 ? function (d) {
    return d.source;
  } : _ref2$source,
      _ref2$target = _ref2.target,
      target = _ref2$target === void 0 ? function (d) {
    return d.target;
  } : _ref2$target,
      _ref2$percent = _ref2.percent,
      percent = _ref2$percent === void 0 ? 0.2 : _ref2$percent,
      children = _ref2.children,
      restProps = _objectWithoutProperties(_ref2, ["className", "innerRef", "data", "path", "x", "y", "source", "target", "percent", "children"]);

  path$$1 = path$$1 || pathHorizontalCurve({
    source: source,
    target: target,
    x: x,
    y: y,
    percent: percent
  });
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-link vx-link-horizontal-curve', className),
    d: path$$1(data)
  }, restProps));
}

function pathVerticalCurve(_ref) {
  var source = _ref.source,
      target = _ref.target,
      x = _ref.x,
      y = _ref.y,
      percent = _ref.percent;
  return function (data) {
    var sourceData = source(data);
    var targetData = target(data);
    var sx = x(sourceData);
    var sy = y(sourceData);
    var tx = x(targetData);
    var ty = y(targetData);
    var dx = tx - sx;
    var dy = ty - sy;
    var ix = percent * (dx + dy);
    var iy = percent * (dy - dx);
    var path$$1 = path();
    path$$1.moveTo(sx, sy);
    path$$1.bezierCurveTo(sx + ix, sy + iy, tx + iy, ty - ix, tx, ty);
    return path$$1.toString();
  };
}
LinkVerticalCurve.propTypes = {
  innerRef: PropTypes.func,
  percent: PropTypes.number,
  x: PropTypes.func,
  y: PropTypes.func,
  source: PropTypes.func,
  target: PropTypes.func,
  path: PropTypes.func,
  children: PropTypes.func
};
function LinkVerticalCurve(_ref2) {
  var className = _ref2.className,
      innerRef = _ref2.innerRef,
      data = _ref2.data,
      path$$1 = _ref2.path,
      _ref2$x = _ref2.x,
      x = _ref2$x === void 0 ? function (d) {
    return d.x;
  } : _ref2$x,
      _ref2$y = _ref2.y,
      y = _ref2$y === void 0 ? function (d) {
    return d.y;
  } : _ref2$y,
      _ref2$source = _ref2.source,
      source = _ref2$source === void 0 ? function (d) {
    return d.source;
  } : _ref2$source,
      _ref2$target = _ref2.target,
      target = _ref2$target === void 0 ? function (d) {
    return d.target;
  } : _ref2$target,
      _ref2$percent = _ref2.percent,
      percent = _ref2$percent === void 0 ? 0.2 : _ref2$percent,
      children = _ref2.children,
      restProps = _objectWithoutProperties(_ref2, ["className", "innerRef", "data", "path", "x", "y", "source", "target", "percent", "children"]);

  path$$1 = path$$1 || pathVerticalCurve({
    source: source,
    target: target,
    x: x,
    y: y,
    percent: percent
  });
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-link vx-link-vertical-curve', className),
    d: path$$1(data)
  }, restProps));
}

function pathRadialCurve(_ref) {
  var source = _ref.source,
      target = _ref.target,
      x = _ref.x,
      y = _ref.y,
      percent = _ref.percent;
  return function (data) {
    var sourceData = source(data);
    var targetData = target(data);
    var sa = x(sourceData) - Math.PI / 2;
    var sr = y(sourceData);
    var ta = x(targetData) - Math.PI / 2;
    var tr = y(targetData);
    var sc = Math.cos(sa);
    var ss = Math.sin(sa);
    var tc = Math.cos(ta);
    var ts = Math.sin(ta);
    var sx = sr * sc;
    var sy = sr * ss;
    var tx = tr * tc;
    var ty = tr * ts;
    var dx = tx - sx;
    var dy = ty - sy;
    var ix = percent * (dx + dy);
    var iy = percent * (dy - dx);
    var path$$1 = path();
    path$$1.moveTo(sx, sy);
    path$$1.bezierCurveTo(sx + ix, sy + iy, tx + iy, ty - ix, tx, ty);
    return path$$1.toString();
  };
}
LinkRadialCurve.propTypes = {
  innerRef: PropTypes.func,
  percent: PropTypes.number,
  x: PropTypes.func,
  y: PropTypes.func,
  source: PropTypes.func,
  target: PropTypes.func,
  path: PropTypes.func,
  children: PropTypes.func
};
function LinkRadialCurve(_ref2) {
  var className = _ref2.className,
      innerRef = _ref2.innerRef,
      data = _ref2.data,
      path$$1 = _ref2.path,
      _ref2$x = _ref2.x,
      x = _ref2$x === void 0 ? function (d) {
    return d.x;
  } : _ref2$x,
      _ref2$y = _ref2.y,
      y = _ref2$y === void 0 ? function (d) {
    return d.y;
  } : _ref2$y,
      _ref2$source = _ref2.source,
      source = _ref2$source === void 0 ? function (d) {
    return d.source;
  } : _ref2$source,
      _ref2$target = _ref2.target,
      target = _ref2$target === void 0 ? function (d) {
    return d.target;
  } : _ref2$target,
      _ref2$percent = _ref2.percent,
      percent = _ref2$percent === void 0 ? 0.2 : _ref2$percent,
      children = _ref2.children,
      restProps = _objectWithoutProperties(_ref2, ["className", "innerRef", "data", "path", "x", "y", "source", "target", "percent", "children"]);

  path$$1 = path$$1 || pathRadialCurve({
    source: source,
    target: target,
    x: x,
    y: y,
    percent: percent
  });
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-link vx-link-radial-curve', className),
    d: path$$1(data)
  }, restProps));
}

function pathHorizontalLine(_ref) {
  var source = _ref.source,
      target = _ref.target,
      x = _ref.x,
      y = _ref.y;
  return function (data) {
    var sourceData = source(data);
    var targetData = target(data);
    var sx = x(sourceData);
    var sy = y(sourceData);
    var tx = x(targetData);
    var ty = y(targetData);
    var path$$1 = path();
    path$$1.moveTo(sx, sy);
    path$$1.lineTo(tx, ty);
    return path$$1.toString();
  };
}
LinkHorizontalLine.propTypes = {
  innerRef: PropTypes.func,
  path: PropTypes.func,
  x: PropTypes.func,
  y: PropTypes.func,
  source: PropTypes.func,
  target: PropTypes.func,
  children: PropTypes.func
};
function LinkHorizontalLine(_ref2) {
  var className = _ref2.className,
      innerRef = _ref2.innerRef,
      data = _ref2.data,
      path$$1 = _ref2.path,
      _ref2$x = _ref2.x,
      x = _ref2$x === void 0 ? function (d) {
    return d.y;
  } : _ref2$x,
      _ref2$y = _ref2.y,
      y = _ref2$y === void 0 ? function (d) {
    return d.x;
  } : _ref2$y,
      _ref2$source = _ref2.source,
      source = _ref2$source === void 0 ? function (d) {
    return d.source;
  } : _ref2$source,
      _ref2$target = _ref2.target,
      target = _ref2$target === void 0 ? function (d) {
    return d.target;
  } : _ref2$target,
      children = _ref2.children,
      restProps = _objectWithoutProperties(_ref2, ["className", "innerRef", "data", "path", "x", "y", "source", "target", "children"]);

  path$$1 = path$$1 || pathHorizontalLine({
    source: source,
    target: target,
    x: x,
    y: y
  });
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-link vx-link-horizontal-line', className),
    d: path$$1(data)
  }, restProps));
}

function pathVerticalLine(_ref) {
  var source = _ref.source,
      target = _ref.target,
      x = _ref.x,
      y = _ref.y;
  return function (data) {
    var sourceData = source(data);
    var targetData = target(data);
    var sx = x(sourceData);
    var sy = y(sourceData);
    var tx = x(targetData);
    var ty = y(targetData);
    var path$$1 = path();
    path$$1.moveTo(sx, sy);
    path$$1.lineTo(tx, ty);
    return path$$1.toString();
  };
}
LinkVerticalLine.propTypes = {
  innerRef: PropTypes.func,
  path: PropTypes.func,
  x: PropTypes.func,
  y: PropTypes.func,
  source: PropTypes.func,
  target: PropTypes.func,
  children: PropTypes.func
};
function LinkVerticalLine(_ref2) {
  var className = _ref2.className,
      innerRef = _ref2.innerRef,
      data = _ref2.data,
      path$$1 = _ref2.path,
      _ref2$x = _ref2.x,
      x = _ref2$x === void 0 ? function (d) {
    return d.x;
  } : _ref2$x,
      _ref2$y = _ref2.y,
      y = _ref2$y === void 0 ? function (d) {
    return d.y;
  } : _ref2$y,
      _ref2$source = _ref2.source,
      source = _ref2$source === void 0 ? function (d) {
    return d.source;
  } : _ref2$source,
      _ref2$target = _ref2.target,
      target = _ref2$target === void 0 ? function (d) {
    return d.target;
  } : _ref2$target,
      children = _ref2.children,
      restProps = _objectWithoutProperties(_ref2, ["className", "innerRef", "data", "path", "x", "y", "source", "target", "children"]);

  path$$1 = path$$1 || pathVerticalLine({
    source: source,
    target: target,
    x: x,
    y: y
  });
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-link vx-link-vertical-line', className),
    d: path$$1(data)
  }, restProps));
}

function pathRadialLine(_ref) {
  var source = _ref.source,
      target = _ref.target,
      x = _ref.x,
      y = _ref.y;
  return function (data) {
    var sourceData = source(data);
    var targetData = target(data);
    var sa = x(sourceData) - Math.PI / 2;
    var sr = y(sourceData);
    var ta = x(targetData) - Math.PI / 2;
    var tr = y(targetData);
    var sc = Math.cos(sa);
    var ss = Math.sin(sa);
    var tc = Math.cos(ta);
    var ts = Math.sin(ta);
    var path$$1 = path();
    path$$1.moveTo(sr * sc, sr * ss);
    path$$1.lineTo(tr * tc, tr * ts);
    return path$$1.toString();
  };
}
LinkRadialStep.propTypes = {
  innerRef: PropTypes.func,
  path: PropTypes.func,
  x: PropTypes.func,
  y: PropTypes.func,
  source: PropTypes.func,
  target: PropTypes.func,
  children: PropTypes.func
};
function LinkRadialStep(_ref2) {
  var className = _ref2.className,
      innerRef = _ref2.innerRef,
      data = _ref2.data,
      path$$1 = _ref2.path,
      _ref2$x = _ref2.x,
      x = _ref2$x === void 0 ? function (d) {
    return d.x;
  } : _ref2$x,
      _ref2$y = _ref2.y,
      y = _ref2$y === void 0 ? function (d) {
    return d.y;
  } : _ref2$y,
      _ref2$source = _ref2.source,
      source = _ref2$source === void 0 ? function (d) {
    return d.source;
  } : _ref2$source,
      _ref2$target = _ref2.target,
      target = _ref2$target === void 0 ? function (d) {
    return d.target;
  } : _ref2$target,
      children = _ref2.children,
      restProps = _objectWithoutProperties(_ref2, ["className", "innerRef", "data", "path", "x", "y", "source", "target", "children"]);

  path$$1 = path$$1 || pathRadialLine({
    source: source,
    target: target,
    x: x,
    y: y
  });
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-link vx-link-radial-line', className),
    d: path$$1(data)
  }, restProps));
}

function pathHorizontalStep(_ref) {
  var source = _ref.source,
      target = _ref.target,
      x = _ref.x,
      y = _ref.y,
      percent = _ref.percent;
  return function (data) {
    var sourceData = source(data);
    var targetData = target(data);
    var sx = x(sourceData);
    var sy = y(sourceData);
    var tx = x(targetData);
    var ty = y(targetData);
    var path$$1 = path();
    path$$1.moveTo(sx, sy);
    path$$1.lineTo(sx + (tx - sx) * percent, sy);
    path$$1.lineTo(sx + (tx - sx) * percent, ty);
    path$$1.lineTo(tx, ty);
    return path$$1.toString();
  };
}
LinkHorizontalStep.propTypes = {
  innerRef: PropTypes.func,
  percent: PropTypes.number,
  x: PropTypes.func,
  y: PropTypes.func,
  source: PropTypes.func,
  target: PropTypes.func,
  path: PropTypes.func,
  children: PropTypes.func
};
function LinkHorizontalStep(_ref2) {
  var className = _ref2.className,
      innerRef = _ref2.innerRef,
      data = _ref2.data,
      path$$1 = _ref2.path,
      _ref2$percent = _ref2.percent,
      percent = _ref2$percent === void 0 ? 0.5 : _ref2$percent,
      _ref2$x = _ref2.x,
      x = _ref2$x === void 0 ? function (d) {
    return d.y;
  } : _ref2$x,
      _ref2$y = _ref2.y,
      y = _ref2$y === void 0 ? function (d) {
    return d.x;
  } : _ref2$y,
      _ref2$source = _ref2.source,
      source = _ref2$source === void 0 ? function (d) {
    return d.source;
  } : _ref2$source,
      _ref2$target = _ref2.target,
      target = _ref2$target === void 0 ? function (d) {
    return d.target;
  } : _ref2$target,
      children = _ref2.children,
      restProps = _objectWithoutProperties(_ref2, ["className", "innerRef", "data", "path", "percent", "x", "y", "source", "target", "children"]);

  path$$1 = path$$1 || pathHorizontalStep({
    source: source,
    target: target,
    x: x,
    y: y,
    percent: percent
  });
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-link vx-link-horizontal-step', className),
    d: path$$1(data)
  }, restProps));
}

function pathVerticalStep(_ref) {
  var source = _ref.source,
      target = _ref.target,
      x = _ref.x,
      y = _ref.y,
      percent = _ref.percent;
  return function (data) {
    var sourceData = source(data);
    var targetData = target(data);
    var sx = x(sourceData);
    var sy = y(sourceData);
    var tx = x(targetData);
    var ty = y(targetData);
    var path$$1 = path();
    path$$1.moveTo(sx, sy);
    path$$1.lineTo(sx, sy + (ty - sy) * percent);
    path$$1.lineTo(tx, sy + (ty - sy) * percent);
    path$$1.lineTo(tx, ty);
    return path$$1.toString();
  };
}
LinkVerticalStep.propTypes = {
  innerRef: PropTypes.func,
  percent: PropTypes.number,
  x: PropTypes.func,
  y: PropTypes.func,
  source: PropTypes.func,
  target: PropTypes.func,
  path: PropTypes.func,
  children: PropTypes.func
};
function LinkVerticalStep(_ref2) {
  var className = _ref2.className,
      innerRef = _ref2.innerRef,
      data = _ref2.data,
      path$$1 = _ref2.path,
      _ref2$percent = _ref2.percent,
      percent = _ref2$percent === void 0 ? 0.5 : _ref2$percent,
      _ref2$x = _ref2.x,
      x = _ref2$x === void 0 ? function (d) {
    return d.x;
  } : _ref2$x,
      _ref2$y = _ref2.y,
      y = _ref2$y === void 0 ? function (d) {
    return d.y;
  } : _ref2$y,
      _ref2$source = _ref2.source,
      source = _ref2$source === void 0 ? function (d) {
    return d.source;
  } : _ref2$source,
      _ref2$target = _ref2.target,
      target = _ref2$target === void 0 ? function (d) {
    return d.target;
  } : _ref2$target,
      children = _ref2.children,
      restProps = _objectWithoutProperties(_ref2, ["className", "innerRef", "data", "path", "percent", "x", "y", "source", "target", "children"]);

  path$$1 = path$$1 || pathVerticalStep({
    source: source,
    target: target,
    x: x,
    y: y,
    percent: percent
  });
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-link vx-link-vertical-step', className),
    d: path$$1(data)
  }, restProps));
}

function pathRadialStep(_ref) {
  var source = _ref.source,
      target = _ref.target,
      x = _ref.x,
      y = _ref.y;
  return function (data) {
    var sourceData = source(data);
    var targetData = target(data);
    var sx = x(sourceData);
    var sy = y(sourceData);
    var tx = x(targetData);
    var ty = y(targetData);
    var sa = sx - Math.PI / 2;
    var sr = sy;
    var ta = tx - Math.PI / 2;
    var tr = ty;
    var sc = Math.cos(sa);
    var ss = Math.sin(sa);
    var tc = Math.cos(ta);
    var ts = Math.sin(ta);
    var sf = Math.abs(ta - sa) > Math.PI ? ta <= sa : ta > sa;
    return "\n      M".concat(sr * sc, ",").concat(sr * ss, "\n      A").concat(sr, ",").concat(sr, ",0,0,").concat(sf ? 1 : 0, ",").concat(sr * tc, ",").concat(sr * ts, "\n      L").concat(tr * tc, ",").concat(tr * ts, "\n    ");
  };
}
LinkRadialStep$1.propTypes = {
  innerRef: PropTypes.func,
  x: PropTypes.func,
  y: PropTypes.func,
  source: PropTypes.func,
  target: PropTypes.func,
  path: PropTypes.func,
  children: PropTypes.func
};
function LinkRadialStep$1(_ref2) {
  var className = _ref2.className,
      innerRef = _ref2.innerRef,
      data = _ref2.data,
      path$$1 = _ref2.path,
      _ref2$x = _ref2.x,
      x = _ref2$x === void 0 ? function (d) {
    return d.x;
  } : _ref2$x,
      _ref2$y = _ref2.y,
      y = _ref2$y === void 0 ? function (d) {
    return d.y;
  } : _ref2$y,
      _ref2$source = _ref2.source,
      source = _ref2$source === void 0 ? function (d) {
    return d.source;
  } : _ref2$source,
      _ref2$target = _ref2.target,
      target = _ref2$target === void 0 ? function (d) {
    return d.target;
  } : _ref2$target,
      children = _ref2.children,
      restProps = _objectWithoutProperties(_ref2, ["className", "innerRef", "data", "path", "x", "y", "source", "target", "children"]);

  path$$1 = path$$1 || pathRadialStep({
    source: source,
    target: target,
    x: x,
    y: y
  });
  if (children) return children({
    path: path$$1
  });
  return React.createElement("path", _extends({
    ref: innerRef,
    className: cx('vx-link vx-link-radial-step', className),
    d: path$$1(data)
  }, restProps));
}

Polygon.propTypes = {
  sides: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
  rotate: PropTypes.number,
  children: PropTypes.func,
  innerRef: PropTypes.func,
  center: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  })
};
var getPoint = function getPoint(_ref) {
  var sides = _ref.sides,
      size = _ref.size,
      center = _ref.center,
      rotate = _ref.rotate,
      side = _ref.side;
  var degrees = 360 / sides * side - rotate;
  var radians = degreesToRadians(degrees);
  return new Point({
    x: center.x + size * Math.cos(radians),
    y: center.y + size * Math.sin(radians)
  });
};
var getPoints = function getPoints(_ref2) {
  var sides = _ref2.sides,
      size = _ref2.size,
      center = _ref2.center,
      rotate = _ref2.rotate;
  return _toConsumableArray(Array(sides).keys()).map(function (side) {
    return getPoint({
      sides: sides,
      size: size,
      center: center,
      rotate: rotate,
      side: side
    });
  });
};
function Polygon(_ref3) {
  var sides = _ref3.sides,
      _ref3$size = _ref3.size,
      size = _ref3$size === void 0 ? 25 : _ref3$size,
      _ref3$center = _ref3.center,
      center = _ref3$center === void 0 ? new Point({
    x: 0,
    y: 0
  }) : _ref3$center,
      _ref3$rotate = _ref3.rotate,
      rotate = _ref3$rotate === void 0 ? 0 : _ref3$rotate,
      className = _ref3.className,
      children = _ref3.children,
      innerRef = _ref3.innerRef,
      restProps = _objectWithoutProperties(_ref3, ["sides", "size", "center", "rotate", "className", "children", "innerRef"]);

  var points = getPoints({
    sides: sides,
    size: size,
    center: center,
    rotate: rotate
  }).map(function (p) {
    return p.toArray();
  });
  if (children) return children({
    points: points
  });
  return React.createElement("polygon", _extends({
    ref: innerRef,
    className: cx('vx-polygon', className),
    points: points.join(' ')
  }, restProps));
}

Circle.propTypes = {
  innerRef: PropTypes.func,
  className: PropTypes.string
};
function Circle(_ref) {
  var className = _ref.className,
      innerRef = _ref.innerRef,
      restProps = _objectWithoutProperties(_ref, ["className", "innerRef"]);

  return React.createElement("circle", _extends({
    ref: innerRef,
    className: cx('vx-circle', className)
  }, restProps));
}

export { Arc, Pie, Line, LinePath, LineRadial, Area, AreaClosed, AreaStack, Bar, BarGroup, BarGroupHorizontal, BarStack, BarStackHorizontal, Stack, stackOffset, STACK_OFFSETS, STACK_OFFSET_NAMES, stackOrder, STACK_ORDERS, STACK_ORDER_NAMES, degreesToRadians, LinkHorizontal, pathHorizontalDiagonal, LinkVertical, pathVerticalDiagonal, LinkRadial, pathRadialDiagonal, LinkHorizontalCurve, pathHorizontalCurve, LinkVerticalCurve, pathVerticalCurve, LinkRadialCurve, pathRadialCurve, LinkHorizontalLine, pathHorizontalLine, LinkVerticalLine, pathVerticalLine, LinkRadialStep as LinkRadialLine, pathRadialLine, LinkHorizontalStep, pathHorizontalStep, LinkVerticalStep, pathVerticalStep, LinkRadialStep$1 as LinkRadialStep, pathRadialStep, Polygon, getPoints, getPoint, Circle };
